<template>
<v-container >
    <v-row  justify="center">

    	<v-col cols="12" class="ma-2">

	    	<v-toolbar dark dense color="primary">
	    	Datos del cliente.

	    	<v-spacer></v-spacer>
				<v-btn
					class="ma-3 pa-3" small color="success"
					@click="saveinfo">Grabar
				</v-btn>

				<v-btn color="info" small
					@click="regresar">
					<v-icon>reply</v-icon>
				</v-btn>
	    	</v-toolbar>

		</v-col>
	</v-row>

	<v-card elevation="10"  >
		<v-row  class="ma-2">
				<!-- DATOS FIJOS VISIBLE -->

				<v-col cols="12" sm="6" xm="12">
			  		<v-text-field
					  	label="Nombre del Cliente"
						v-model ="Nomcli"
						></v-text-field>
					</v-col>

				<v-col cols="12" sm="3"  xm="12">
			  		<v-text-field class="pa-2"
						label="Nombre comercial"
						v-model ="Nomcomer"
					></v-text-field>
			    </v-col>

		</v-row>
	</v-card>
			<!-- {{ numcliActual}} {{ idweb}} -->

			 <!-- Datos fiscales -->

	<v-card elevation="10"  height="auto" >
    	<v-row  class="ma-2">

	    	<v-text-field
	      	label="Calle"
	      	v-model="Calle"
	      	filled
          shaped
    		></v-text-field>

  	  	<v-text-field
	      	label="Número exterior"
	      	v-model="Numext"
	      	filled
      			shaped
    		></v-text-field>

      	<v-text-field
      	    label="Número Interior"
      	    v-model="Numint"
      	    filled
      			shaped
      	></v-text-field>

      	<v-text-field
        	label="Colonia"
      	    v-model="Colonia"
      	    filled
      			shaped
      	></v-text-field>

        	<v-text-field
      	    label="Ciudad"
      	    v-model="Ciudad"
      	    filled
      			shaped
      	></v-text-field>

        	<v-text-field
      	    label="Estado"
      	    v-model="Estado"
      	    filled
                shaped
      	></v-text-field>

   		<v-text-field
        	label="Pais"
      	    v-model="Pais"
      	    filled
                shaped
      	></v-text-field>

      	<v-text-field
        	label="Cp"
      	    v-model="Cp"
      	    filled
      			shaped
      	></v-text-field>

    		<v-text-field
	      	label="Telefono"
	      	v-model="Telefono"
	      	filled
          		shaped
    		></v-text-field>

  	   		<v-text-field class="text-uppercase"
      	    label="RFC"
      	    v-model="Rfc"
      	    filled
      			shaped
      			@blur="validarclienterfc"
    		></v-text-field>

    </v-row>

	    </v-card>

		<v-card elevation="10"  height="auto" >
    		<v-row  class="ma-2">

		      	<v-flex xs6  class="pa-1">
			      	<v-text-field
			      	    label="Nombre Contacto"
			      	    filled
                  shaped
			      	    v-model="Atvent"
			      	></v-text-field>
	      	    </v-flex>

				<v-flex xs6 sm4 class="pa-1">
		      		<v-text-field
				      	label="Email"
				      	filled
	              		shaped
				      	v-model="Email1"
		      		></v-text-field>
	      	    </v-flex>

	      	    <!-- <v-flex xs6  class="pa-1">
		      		<v-text-field
				      	label="Nombre atencion cobranza"
				      	filled
	              		shaped
				      	v-model="Atcobr"
		      		></v-text-field>
	      	    </v-flex>

			    <v-flex xs6 sm4 class="pa-1">
		      		<v-text-field
				        label="Emai2"
				     	filled
	              		shaped
				      	v-model="Email2"
		      		></v-text-field>
	      	  </v-flex>
 -->
				</v-row>
			</v-card>

    <v-snackbar
        v-model="snackbar"
        :timeout="3000"
        top
        :color="color"
        >
          {{text}}
        <v-btn
            color="white"
            text
            @click="snackbar = false"
            >
            Close
         </v-btn>
    </v-snackbar>

</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      e1: 1,
      text: '',
      snackbar: false,
      color: '',

      Idweb: '',

      Cliente: [],

      // Variables
      Nomcli: '',
      Nomcomer: '',
      Precioutil: '1',

      Calle: '',
      Numext: '',
      Numint: '',
      Colonia: '',
      Ciudad: '',
      Estado: '',
      Pais: '',
      Cp: '',
      Telefono: '',
      Rfc: '',

      Atvent: '',
      Atcobr: '',
      Email1: '',
      Email2: '',

      Puesto: '',
      Atpagos: '',
      Emailfac: '',
      Telefono2: '',

      // precioutil: '1',
      ListaPrecios: ['1', '2', '3', '4', '5'],

      Obs: '',

	    readonly: false,
	    nModo: '',
	    modo: '',
	    numcli: '',
	    datosusuarios2: null,
	    idweb: '',
	    numcliActual: '',
	    clienteActual: false
    }
  },

  created () {
    const cnumcli = this.getdatosUsuario.numcli
    console.log('numcli', cnumcli)
    console.log('getdatosUsuario', this.getdatosUsuario)

    console.log("getconfig", this.getConfig.precioutil)
	    this.idusuariosweb = this.getdatosUsuario.idusuariosweb
	    this.idvend = this.getdatosUsuario.idvend

	    // Para no modificar API. Actualizan todo el renglón.
	    this.datosusuarios2 = this.getdatosUsuario

    if (cnumcli !== '' && cnumcli !== '0') {
    	this.nModo = 2
    	this.numcli = cnumcli

    	console.log('Cliente Actual')

  	   //  this.cliente = this.getClientes
		    // console.log("getCliente", this.cliente)
		    // this.idweb = this.cliente.idweb

    	// Cargar por numcli
    	// console.log("CLIENTE numcli", cnumcli)
    	this.readonly = true
      this.CargarInfo(this.numcli)
      this.modo = 'Editar'
    }

    if (cnumcli == '' || cnumcli == '0') {
    	this.numcli = ''
    	this.nModo = 1
    	this.modo = 'Nuevo'
    	console.log('Cliente Nuevo')
    }
  },

  computed: {
  	...mapGetters('Login', ['getdatosUsuario', 'getClientes', 'getCliente', 'getCIA', 'getInfoVend', 'getModo']),
  	...mapGetters('config', ['getConfig']),

    validaInfo () {
      return this.Nomcli !== '' &&
      	this.rfc !== '' &&
        this.Telefono !== ''
    },

    valClienteExiste(){
    	return this.clienteActual
    }

  },

  methods: {

    ...mapActions('Login', ['actualizaCliente']),

    	//Se movio de coputed a methos
    validarclienterfc () {
    	console.log('RFC', this.Rfc)
    	// SOLO PARA CLIENTES NUEVOS
    	if (this.nModo == 1) {
	      this.$http.get('auth/api/v1/clientebyrfc/' + this.Rfc).then(response => {
	  			
	  			//SI NO LO ENCUNTRA REGRESA ERROR 500
	   			console.log("clientebyrfc",response)
    
          // console.log("clientebyrfc",response.data.numcli)
          if (response.data === "Sin Resultados") {

          } else {

          	if ( response.data.numcli != '') {
            	this.text = 'El RFC ya ha sido registrado con anterioridad'
		            this.snackbar = true
		            this.numcliActual = response.data.numcli
		            this.idweb = response.data.idweb
		            // CARGAR INFO con numcli
		            this.nModo = 2
		            this.CargarInfo(this.numcliActual)
		            return true
            }
          }
	            // this.cliente.numcli = response.body.numcli
	            // this.cliente.idweb = response.body.idweb
	            
        }).catch(error => {

          this.snackbar = true ;
          this.color="error"
          this.text = "Problemas para grabar el cliente"
          console.log(error)
          return false
	     })
	    }

	    //No es cliente
	    return false
    },

	  CargarInfo (numcli) {
	  	console.log("numclie", numcli)
	    if (numcli != undefined){
	    	const cNumcli = numcli.trim()

	    	this.$http.get('auth/api/v1/clientebynumcli/' + cNumcli).then(response => {
			  	// console.log('clientebynumcli ', response.data)
		 		  this.Cliente 	= response.data
	        	this.numcli = this.Cliente.numcli

	        	this.Nomcli 	= this.Cliente.nomcli
	        	this.Nomcomer 	= this.Cliente.nomcomer
	        	// this.Precioutil = this.Cliente.precioutil

	        	this.Calle 		= this.Cliente.Calle
	        	this.Numext 	= this.Cliente.Numext
	        	this.Numint 	= this.Cliente.Numint
	        	this.Colonia 	= this.Cliente.colonia
	        	this.Ciudad 	= this.Cliente.Ciudad
	        	this.Estado 	= this.Cliente.Estado
	        	this.Pais 		= this.Cliente.Pais
	        	this.Cp 		=	this.Cliente.Cp
	        	this.Rfc = this.Cliente.Rfc.toUpperCase()
	        	this.Telefono = this.Cliente.telefono

	        	this.Atvent 	= this.Cliente.atvent
	        	this.Atcobr 	= this.Cliente.atcobr
	        	this.Email1		= this.Cliente.email1
	        	this.Email2 	= this.Cliente.email2
	        	this.Precioutil = this.Cliente.precioutil
			
			  	}, error => {
		  			this.snackbar = true
		            this.color = 'error'
		            this.text = 'Problemas para cargar el cliente'
    	    console.log(error)
				  })

	    }
	  },

	    saveinfo () {
	        const payload = {
	      		numcli: this.numcli,
	      		Nomcli: this.Nomcli,
	      		Nomcomer: this.Nomcomer,
		      	Calle: this.Calle,
		      	Numext: this.Numext,
		      	Numint: this.Numint,
		      	Colonia: this.Colonia,
		      	Ciudad: this.Ciudad,
		      	Estado: this.Estado,
		      	Pais: this.Pais,
		      	Cp: this.Cp,
		      	Rfc: this.Rfc.toUpperCase(),
		      	Atvent: this.Atvent,
		      	Atcobr: this.Atcobr,
		      	Email1: this.Email1,
		      	Email2: this.Email2,
		      	Telefono: this.Telefono,
		      	Precioutil: this.Precioutil
		  	   }

		  	// console.log("payload",payload)

		  	console.log("validaifo", this.validaInfo)
		    // VALIDAR INFORMACION
		    if (this.validaInfo === false) {
		        this.snackbar = true; this.color = 'red'
		        this.text = 'Debe llenar nombre del cliente, RFC y telefono'
		      return
		    }

		    // VALIDAR INFORMACION
		    if (this.nModo == 1) {

		    	// MANDAR BUSCAR EL CLIENTE NUEVO
		    	this.validarclienterfc()

			    if (this.clienteActual=== true) {
			        this.snackbar = true; this.color = 'orange'
			        this.text = 'El cliente ya está registrado y es ' + this.numcliActual

			        // ACTUALIZAR PAYLOAD Y CAMBIAR a MODO 2.
			        payload.numcli = this.numcliActual
			        this.nModo = 2
			        console.log('MODO 2 ya existe', payload.numcli)
			      return
			    }
      }

		    // MODO NUEVO
	        if (this.nModo == 1) {

	        	// Asignar lista de precioa 1 a cliente nuevo.
	        	// payload.Precioutil = '1'
		        // CAMBIAR A TRAR LA LISTA DE PRECIOS DE LA TIENDA.
		        payload.Precioutil = this.getConfig.precioutil

	        	console.log('SaveInfo nuevo 1 NUEVO Lista de Precios Tienda ', payload)
	        	// TRAER EL SIGUIENTE NUMCLI CONSECUTIVO SIGCLI EN CIAWS
	            this.$http.post('api/v1/lastnumcli').then(response => {
	            	console.log('Save info lastnumcli', response)
			        	// le agregamos al cliente que vamos a registrar su numero de cliente
				        payload.numcli = response.body.Numcli
				        this.numcli = payload.numcli

				        console.log('payloadclietes nuevo', payload)
			        	// GRABAR CLIENTE.
		            this.$http.post('auth/api/v1/clientes', payload).then(response => {
		            	console.log('response post clientes', response)
			            this.snackbar = true
			            this.color = 'success'
			            this.text = 'El Cliente se guardo correctamente.'

			            // GRAAMOS NUMCLIE en usuarios.numcli
			            this.actualizaUsuario(payload.numcli)
			            setTimeout(() => this.$router.push({ name: 'home' }).catch(err => {}), 2000)
	            
		            }).catch(error => {
		            	this.snackbar = true
			            this.color = 'error'
			            this.text = 'Problemas para grabar el cliente'
			            console.log(error)
		            })
        			}).catch(error => {
	            	this.snackbar = true
		            this.color = 'error'
		            this.text = 'Problemas para grabar el cliente'
	                console.log(error)
	            })
	        } else {
        // this.cliente = this.getCliente
			    this.cliente = this.getClientes
			    // console.log("getCliente", this.cliente)

			    // console.log("numcli modo2", this.numcli)

	          	// TRAEMOS EL IDweb del cliente
	        	var cIdweb = this.cliente.idweb
	        	payload.numcli = this.numcli

	        	// ACTUALIZAMOS LA INFORMACIÖN DEL CLIENTE ACTUAL DE SAIT.
	        	if (this.numcliActual != '') {
	        		payload.numcli = this.numcliActual
	        		cIdweb = this.idweb
	        		// console.log("payload 2", cIdweb, payload.numcli)
	        	}

	        	 console.log('payload', payload)
	            this.$http.put('auth/api/v1/clientes/' + cIdweb, payload).then(response => {
	            	console.log('put response')
	                this.snackbar = true
	                this.color = 'success'
	                this.text = 'El Cliente se modifico correctamente.'

			            // GRAAMOS NUMCLIE en usuarios.numcli
			            this.actualizaUsuario(payload.numcli)

	                // ACTUALIZAR el STATE.
	                this.actualizaCliente(payload.numcli)

	                setTimeout(() => this.$router.push({ name: 'home' }).catch(err => {}), 2000)
	            }).catch(error => {
	            	this.snackbar = true
		            this.color = 'error'
		            this.text = 'Problemas para grabar el cliente'
	                console.log(error)
	            })
	        }
	    },

	    actualizaUsuario (numcli) {
	        console.log('numcli', numcli)
	        console.log('idusuariosweb', this.idusuariosweb)
		    	// CUANDO ACTUALIZAMOS SOLO OCUPAS LOS CAMPOS que cambian.

	        // actualizamos el usuario con su nuevo cliente
	        this.datosusuarios2.numcli = numcli

	        this.$http.put('auth/api/v1/usuarios/' + this.idusuariosweb, this.datosusuarios2).then(response => {
	        	// console.log("Actualizar Usuario. ",response)

	          // mandamos a llamar una función que nos trae los datos del nuevo cliente para guardarlo en el estate
	          this.actualizaCliente(this.numcli)
	        }).catch(error => {
	        	this.snackbar = true
	            this.color = 'error'
	            this.text = 'Problemas para actualizar el usuario.'
	            console.log(error)
	        })
	    },

	    regresar () {
	      this.$router.push({ name: 'home' })
	      .catch(err => { console.log(err) })
	    }

  }

}

</script>
